.pagination-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;

  .page-title {
    p {
      font-family: $font-family-jakarta;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 20px;
      letter-spacing: 0.14px;
      color: $neutral-50;
      margin-bottom: 0px;
    }
  }

  .pagination-and-dropdown-start {
    display: flex;
    align-items: center;

    .pagination-dropdown {
      .form-select {
        border-radius: 10px;
        border: 1px solid $neutral-60;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .padination-start {
      margin-left: 20px;

      ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
          float: left;

          button {
            background: transparent;
            border: none;
            height: 35px;
            width: 35px;
            font-family: $font-family-jakarta;
            font-size: $font-size-14;
            font-style: normal;
            font-weight: $font-weight-medium;
            line-height: 20px;
            letter-spacing: 0.14px;
            border-radius: 10px;
            color: $neutral-60;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .active-page {
            background: $primary-color;
            color: $color-white;
          }
        }
      }
    }
  }
}

.listing-pagination {
  .page-link.disabled {
    pointer-events: none;
    background-color: transparent;
    border-color: transparent;
  }
  .page-link {
    border: none;
    color: $neutral-60;
    font-family: $font-family-jakarta;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.14px;
    text-transform: capitalize;
    cursor: pointer;
    span {
      svg {
        display: flex;
        width: 18px;
        height: 18px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .page-link.active {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
    border-radius: 10px;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 6px 0px;
    justify-content: center;
    align-items: center;
  }
}

.product-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  .product-num-page {
    p {
      color: $neutral-50;
      text-align: center;
      font-family: $font-family-jakarta;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.14px;
      text-transform: capitalize;
      margin: 0;
    }
  }
  .listing-pagination {
    display: flex;
    align-items: center;
    nav {
      ul {
        margin-bottom: 0;
      }
    }
    .dropdown {
      button {
        background-color: transparent;
        border-radius: 10px;
        border: 1px solid $neutral-60;
        color: $neutral-60;
        text-align: center;
        font-family: $font-family-jakarta;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.14px;
        text-transform: capitalize;
        padding: 6px 12px 6px 12px;
        position: relative;
        &::after {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0;
          border-right: 0;
          border-bottom: 0;
          border-left: 0;
          background-image: url('../../../public/images/CaretLeft.svg');
          background-position: right;
          background-repeat: no-repeat;
          background-size: cover;
          height: 15px;
          width: 18px;
          top: 5px;
          position: relative;
        }
      }
    }
    
  }
}

@media only screen and (max-width: 767px) {
  .product-pagination .listing-pagination {
    justify-content: space-between;
    width: 100%;
  }
}
