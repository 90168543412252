.sidebar {
  width: 240px;
  position: fixed;
  padding-top: 12px;
  height: 100vh;
  padding: 0px 24px;
  padding-top: 15px;

  .sidebar-company-logo {
    margin-bottom: 35px;
  }

  .sidebar-accordion {
    .sidebar-routing-link {
      text-decoration: none;
    }

    .sidebar-button {
      margin-bottom: 12px;

      button {
        border: none;
        background: transparent;
        font-size: $font-size-14;
        font-family: $font-family-jakarta;
        font-weight: $font-weight-semi-bold;
        line-height: 20px;
        letter-spacing: 0.14px;
        text-transform: capitalize;
        color: $neutral-50;
        padding: 12px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        width: 100%;

        &:hover {
          color: $primary-color;
          background: $neutral-20;
        }

        &:hover svg {
          color: $primary-color;
        }

        svg {
          margin-right: 12px;
          color: $neutral-50;
        }
      }
    }

    .accordion-button-start {
      .accordion {
        .accordion-item {
          border: none;
          border-radius: none !important;
          background-color: transparent;

          &:last-of-type {
            border-radius: none !important;
          }

          &:first-of-type {
            border-radius: none !important;
          }

          .accordion-header {
            .collapsed {
              .dropdown-arrow {
                // transform: rotate(180deg);
                // transition: all 0.3s ease-out;
                // [aria-expanded="true"]{
                //   transform: rotate(180deg);
                //   transition: all 0.3s ease-out;
                // }
              }
            }

            .accordion-button {
              border: none;
              background: transparent;
              font-size: $font-size-14;
              font-family: $font-family-jakarta;
              font-weight: $font-weight-semi-bold;
              line-height: 20px;
              letter-spacing: 0.14px;
              text-transform: capitalize;
              color: $neutral-50;
              padding: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin-bottom: 12px;
              border-radius: 10px;

              &:focus {
                outline: none;
                box-shadow: none;
                border: none;
              }

              &:not(.collapsed) {
                box-shadow: none;
              }

              &:hover {
                color: $primary-color;
                background: $neutral-20;
              }

              &:hover svg {
                color: $primary-color;
              }

              &::after {
                display: none;
              }

              svg {
                margin-right: 12px;
                color: $neutral-50;
                transition: all 0.3s ease-out;
              }
            }
          }

          .accordion-collapse {
            .accordion-body {
              padding: 0px;
              padding-left: 22px;

              .sub-page-links {
                border-left: 2px solid $neutral-30 !important;
                padding-left: 12px;
                padding-right: 18px;

                ul {
                  margin: 0;
                  padding: 0;
                  list-style: none;

                  a {
                    text-decoration: none;

                    li {
                      font-family: $font-family-jakarta;
                      font-size: $font-size-14;
                      font-style: normal;
                      font-weight: $font-weight-semi-bold;
                      line-height: 20px;
                      letter-spacing: 0.14px;
                      text-transform: capitalize;
                      color: $neutral-50;
                      padding: 12px;
                      margin-bottom: 12px;
                      border-radius: 10px;

                      &:hover {
                        color: $primary-color;
                        border-radius: 10px;
                        background: $neutral-20;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidebar-and-main-flex {
  display: flex;
  width: 100%;
}

.header-and-main-content {
  width: calc(100% - 240px);
  margin-left: 240px;
}

.active-sidebar-button {
  color: $primary-color !important;
  background: $neutral-20 !important;

  svg {
    color: $primary-color !important;
  }
}

.sidebar .sidebar-accordion .accordion-button-start .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) .dropdown-arrow {
   transform: rotate(180deg);
   transition: all 0.3s ease-out;
}
.navbar-close-button {
  display: none;
  button {
    background-color: transparent;
    border: none;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 6px;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-close-button {
    display: block;
  }
}