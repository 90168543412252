// @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url("../../../src/fonts/stylesheet.css");

$font-family-jakarta: 'Plus Jakarta Sans', sans-serif;


// ---- font-weight-variable ----
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;



// ---- font-size-variable ----
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-21: 21px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-34: 34px;
$font-size-35: 35px;
$font-size-36: 36px;
$font-size-38: 38px;
$font-size-40: 40px;
$font-size-48: 48px;
$font-size-56: 56px;



$color-dark: #101623;
$color-red: #FF5630;
$color-white: #fff;
$color-yellow: #FFAB00;

// --- dropdown-user-logo-color ----
$user-logo-bg: #214344;

// --- status-color ---
$info: #0065FF;

// --- table-img-bg-color ---
$image-bg-color: #F2F2F2;


$neutral-20: #F4F5F6;
$neutral-30: #E5E7EB;
$neutral-40: #D3D6DA;
$neutral-50: #A0A8B0;
$neutral-60: #707784;
$neutral-80: #3B4453;
$neutral-90: #232B39;


$primary-color: #5E59FF;
$primary-border-color: #7975FF;
$success-color: #36B37E;