.product-details-page {
  .details-image-scroll {
    overflow-x: scroll;
    width: 100%;
    max-width: 100%;
    display: grid;

    .new-image-flex {
      display: flex;
      align-items: center;

      .product-image {
        width: 160px;
        margin-bottom: 15px;
        height: 160px;
        background: $image-bg-color;
        padding: 16px;
        border-radius: 18px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          mix-blend-mode: multiply;
          filter: contrast(1);
          // border-radius: 18px;
          // object-fit: fill;
        }
      }
    }
  }

  .pd-image {
    width: 160px;
    height: 160px;
    border-radius: 5px;
  }

  .product-list {
    border-top: 1px solid $neutral-30;
    padding: 28px 0;

    &:last-child {
      padding: 28px 0 0;
    }

    .product-name {
      color: $color-dark;
      font-style: normal;
      line-height: 22px;
      font-family: $font-family-jakarta;
      font-size: $font-size-16;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
    }

    .product-item-id {
      color: $neutral-80;
      font-family: $font-family-jakarta;
      font-size: $font-size-16;
      font-style: normal;
      font-weight: $font-weight-regular;
      line-height: 22px;

      .publish-status {
        text-transform: capitalize;
        border-radius: 20px;
        background: rgba(204, 224, 255, 0.5);
        padding: 4px 16px;
        width: auto;
        display: inline-block;

        p {
          font-family: $font-family-jakarta;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: $font-weight-medium;
          color: $info;
          margin-bottom: 0px;
          letter-spacing: 0.14px;
        }
      }

      .unpublish-status {
        text-transform: capitalize;
        border-radius: 20px;
        background: $neutral-30;
        padding: 4px 16px;
        width: auto;
        display: inline-block;

        p {
          font-family: $font-family-jakarta;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: $neutral-50;
          margin-bottom: 0px;
          letter-spacing: 0.14px;
        }
      }
    }
  }
}

.product-rules {
  border-bottom: 1px solid $neutral-30;
  padding-bottom: 28px !important;
}

.product-rule-inner {
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .product-rule-price {
    padding-right: 50px;

    span {
      color: $neutral-80;
      font-family: $font-family-jakarta;
      font-size: $font-size-16;
      font-style: normal;
      font-weight: $font-weight-regular;
      line-height: 22px;
    }
  }

  .custom-select {
    .dropdown {
      button {
        border-radius: 8px;
        border: 1px solid $neutral-30;
        background: #fff;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
        padding: 13px 14px;
        color: $neutral-80;
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-regular;
        line-height: 22px;
        width: 156px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        &::after {
          display: none;
        }
      }
    }

    .show {
      .dropdown-toggle {
        .dropdown-icon {
          transform: rotate(-180deg);
          transition: all 0.3s ease-out;
        }
      }

      .dropdown-menu {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08),
          0px 1px 2px 0px rgba(0, 0, 0, 0.08);
        border: none;
        padding: 4px;
        position: absolute !important;
        inset: unset !important;
        transform: unset !important;
        right: 14px !important;
        top: 45px !important;
        min-width: 127px !important;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;

        .dropdown-item {
          border-radius: 8px;
          padding: 6px;
          font-family: $font-family-jakarta;
          font-size: $font-size-16;
          font-style: normal;
          font-weight: $font-weight-regular;
          line-height: 22px;
          color: $neutral-80;

          &:hover {
            border-radius: 8px;
            background: $neutral-20;
          }

          &:active {
            color: $neutral-80;
          }
        }
      }
    }
  }
}

.rules-box {
  padding-top: 0px !important;
  padding-bottom: 200px !important;

  .percentage-field {
    margin: 0 24px;
  }
}

.percentage-input-field {
  display: flex;
  align-items: center;

  .product-rule-price {
    padding-right: 24px;
  }

  .form-control {
    border-radius: 8px;
    border: 1px solid $neutral-30;
    background: #fff;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
    padding: 13px 14px;
    width: 114px;
    color: $neutral-80;
    font-family: $font-family-jakarta;
    font-size: $font-size-16;
    font-style: normal;
    font-weight: $font-weight-regular;
    line-height: 22px;

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid $neutral-30;
    }
  }
}

.main-title {
  display: flex;
}

.back-button {
  margin-right: 8px;

  button {
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

// ---- product-details-input-field-auto-width-set-css -----
.product-details-input-field-half-set-width {
  .custom-field {
    .form-control {
      width: 40% !important;
    }
  }
}

.field-span-gap {
  margin-bottom: 20px;
  display: inherit;
}

@media only screen and (min-width: 1280px) and (max-width: 1400px) {
  .product-rule-inner {
    .price-width {
      .show .dropdown-menu {
        min-width: 87px !important;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .product-rule-inner .custom-select .dropdown button {
    width: 141px;
  }

  .rules-box .percentage-field {
    margin: 0 12px;
  }

  .product-rule-inner .custom-select .show .dropdown-menu {
    right: 7px !important;
  }

  body .price-width {
    .dropdown {
      button {
        width: 100px !important;
      }
    }
  }

  .rules-box .product-details-page .percentage-input-field .product-rule-price {
    padding-right: 12px;
  }

  body .rules-model .modal-dialog .product-details-page .product-list {
    padding: 14px 14px 14px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .product-rule-inner .custom-select .show .dropdown-menu {
    top: 42px !important;
  }

  .rules-model {
    .product-rule-inner {
      align-items: flex-start;
      flex-direction: column;
    }

    .rules-box .percentage-field {
      margin: 12px 0px;
    }

    .rules-box {
      padding-bottom: 20px !important;
    }

    .product-rule-inner .product-rule-price {
      padding-right: 20px;
      padding-bottom: 10px;
    }

    .product-rule-inner .custom-select .dropdown button {
      padding: 10px 14px;
      font-size: 14px;
    }

    .percentage-input-field .form-control {
      padding: 10px 14px;
    }

    .percentage-input-field {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .percentage-input-field .form-control {
      width: 140px;
    }

    .product-padding-left {
      padding-left: 50px;
      padding-top: 9px;
      display: block;
    }

    .product-rule-inner .custom-select.cat-drop .show .dropdown-menu {
      right: -110px !important;
    }
  }

  .rules-model.rules-box .percentage-field {
    margin: 13px 0px;
  }

  body .price-width .dropdown button {
    width: 140px !important;
  }

  .rules-model .modal-dialog .product-rule-inner .product-rule-price {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-input-field-half-set-width .custom-field .form-control {
    width: 100% !important;
  }

  .product-rule-inner {
    align-items: flex-start;
    flex-direction: column;
  }

  .rules-box .percentage-field {
    margin: 12px 0px;
  }

  .rules-box {
    padding-bottom: 20px !important;
  }

  .product-rule-inner .product-rule-price {
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .product-rule-inner .custom-select .dropdown button {
    padding: 10px 14px;
    font-size: 14px;
  }

  .percentage-input-field .form-control {
    padding: 10px 14px;
  }

  .percentage-input-field {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .product-details-page .product-list {
    padding: 20px 0;
  }

  .product-details-page .product-list .product-name {
    padding-bottom: 5px;
  }
}

.remove-border {
  .product-details-page .product-list {
    border-top: none;
  }

  .product-rules {
    border-bottom: none;
  }
}

.apply-button-new {
  margin-top: 0px !important;
}

.product-rule-dropdown-and-button-flex {
  display: flex;
  align-items: center;
}