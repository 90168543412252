.rule-list-section {
  .search-and-add-rule-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .common-search {
      position: relative;

      .form-control {
        border-radius: 8px;
        background: $neutral-20;
        padding: 9px 14px 9px 42px;
        height: 40px;
        width: 346px;
        border: none;
        outline: none;

        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }

        &::placeholder {
          font-family: $font-family-jakarta;
          font-weight: $font-weight-regular;
          font-size: $font-size-16;
          color: $neutral-50;
          line-height: 22px;
        }
      }

      .search-iocn {
        position: absolute;
        top: 6px;
        left: 14px;
      }
    }

    .add-rule-btn {
      button {
        display: flex;
        align-items: center;
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        color: $color-white;
        border: none;
        border-radius: 10px;
        background: $primary-color;
        justify-content: center;
        padding: 12px;
        line-height: 16px;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  .rules-and-action-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px;
    border-radius: 8px;
    background: #fafafa;
    margin-top: 32px;
    margin-bottom: 12px;

    .rules-title {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-14;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        color: $neutral-50;
        line-height: 20px;
        letter-spacing: 0.14px;
        margin-bottom: 0px;
        padding-right: 37px;
      }
    }
  }

  .added-rule-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 12px;
    border-bottom: 1px solid $neutral-30;

    .if-else-rule-title {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        color: $neutral-80;
        line-height: 22px;
        margin-bottom: 0px;
      }
    }

    .rule-edit-and-delete-btn {
      display: flex;
      align-items: center;

      .rule-edit-btn {
        button {
          border: none;
          border-radius: 10px;
          background: $neutral-20;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .rule-delete-btn {
        button {
          border: none;
          border-radius: 10px;
          background: $neutral-20;
          padding: 12px;
          margin-left: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

// model css ------

.rules-model {
  .modal-dialog {
    max-width: 90% !important;
    margin: 30px auto 0;
    .product-details-page .product-list {
      padding: 14px 24px 14px !important;
      margin-top: 40px;
    }
    .product-rule-inner .product-rule-price .rule-product-title {
      font-weight: $font-weight-semi-bold;
    }
    .product-rule-inner .product-rule-price {
      padding-right: 24px;
    }
    .product-rule-inner {
      padding: 0 0px;
    }
  }
  .modal-footer {
    border: none;
    padding: 0;
    margin: 0;
    .common-three-button {
      margin-top: 32px;
    }
  }
}
.product-padding-left {
  padding-left: 24px;
}

.common-three-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  button {
    border-radius: 10px;
    background: $primary-color;
    padding: 16px 32px;
    border: none;
    color: #fff;
    font-family: $font-family-jakarta;
    font-size: $font-size-16;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .rule-list-section .search-and-add-rule-btn {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
  .rule-list-section .search-and-add-rule-btn .common-search .form-control {
    width: 100%;
  }
  .rule-list-section .search-and-add-rule-btn .common-search {
    width: 100%;
    margin-bottom: 20px;
  }
  .rule-list-section .added-rule-box {
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 0px;
    // flex-direction: column;
  }
  .rule-list-section .added-rule-box .if-else-rule-title p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 12px;
    margin-right: 5px;
  }
  .rule-list-section .search-and-add-rule-btn .add-rule-btn button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .form-filed-shopify-common {

    .common-three-button {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
      button {
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}


body .form-filed-shopify-common {
  .edit-password-modal .modal-body {
    padding: 0px 0px;
  }
}


// ----- delete-modal-style ----
.delete-modal{
  padding: 24px;
  .modal-content{
    max-width: 300px !important;
    margin: 0 auto;
  }
  .modal-body{
    padding: 0px;
    .delete-popup-title{
      p{
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-regular;
        line-height: 22px;
        color: $neutral-80;
        margin-bottom: 0px;
      }
    }
  }
  .modal-footer{
    padding: 0px;
    border: 0px;
  }
}