@import "../variable/variable.scss";
@import "../login/login.scss";
@import "../sidebar/sidebar.scss";
@import "../header/header.scss";
@import "../product/product.scss";
@import "../product/product-details.scss";
@import "../rule/rule.scss";
@import "../pagination/pagination.scss";

body {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.loader {
  left: 50%;
  top: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader #spinner {
  box-sizing: border-box;
  stroke: #1866f3;
  stroke-width: 3px;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite,
    rotate 1.6s linear infinite;
  animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite,
    rotate 1.6s linear infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@-webkit-keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes line {
  0% {
    stroke-dasharray: 2, 85.964;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.invalid-feedback {
  color: $color-red !important;
  font-size: $font-size-12 !important;
  font-weight: $font-weight-medium !important;
  font-family: $font-family-jakarta !important;
}

.main-container {
  margin-left: 240px;
}

.common-error-message {
  color: $color-red;
  font-size: $font-size-14;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family-jakarta;
  line-height: 20px;
  width: 58%;
}

@media only screen and (max-width: 1700px) {
  .common-error-message {
    width: 72%;
  }
}

// ------- custome-scroll-css ----------
.main-content {
  overflow-y: auto;
  height: calc(100vh - 71px);
  background: $neutral-20;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 2px grey;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

.product-rule-inner .custom-select .show .dropdown-menu::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

// ------ common-checkbox-css --------

.table-new-checkbox {
  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-border-color;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .form-check-input:checked[type="checkbox"] {
    background-image: url("../../../public/images/check.svg");
    background-size: auto;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid $neutral-60;
    border-radius: 6px;
    margin-right: 6px;
    margin: 0px !important;

    &:focus {
      outline: none !important;
      border-color: none !important;
    }
  }

  .form-check-input[type="checkbox"] {
    border-radius: 6px;
  }
}

.main-title-aco-checkbox {
  label {
    font-size: $font-size-20 !important;
    color: $color-dark !important;
  }
}

.aco-field-scroll {
  margin-top: 53px;
  overflow-y: scroll;
  height: calc(100vh - 185px);
}

.cancel-and-save-btn-aco-checkbox-field {
  margin-top: 15px;
  text-align: end;
}

.aco-page-new-checkbox {
  margin-bottom: 17px;

  label {
    font-family: $font-family-jakarta;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-16;
    line-height: 22px;
    margin-left: 10px;
    color: $neutral-80;
  }

  .form-check {
    padding: 0px;
    display: flex;
    align-items: center;
  }

  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-border-color;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .form-check-input:checked[type="checkbox"] {
    background-image: url("../../../public/images/check.svg");
    background-size: auto;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid $neutral-60;
    border-radius: 6px;
    margin-right: 6px;
    margin: 0px !important;

    &:focus {
      outline: none !important;
      border-color: none !important;
    }
  }

  .form-check-input[type="checkbox"] {
    border-radius: 6px;
  }
}

// ------- common-buttons --------

.border-blue-btn {
  background: transparent;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid $primary-color;
  color: $primary-color;
  font-size: $font-size-16;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family-jakarta;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;

  &:disabled {
    background: $neutral-30;
    color: $neutral-50;
    border: 1px solid $neutral-30;
  }
}

.greren-btn {
  border-radius: 10px;
  padding: 16px;
  border: 1px solid $success-color;
  background: $success-color;
  color: $color-white;
  font-size: $font-size-16;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family-jakarta;
  margin-left: 12px;
  line-height: 16px;

  &:disabled {
    background: $neutral-30;
    color: $neutral-50;
    border: 1px solid $neutral-30;
  }
}

.common-close-btn {
  background: transparent;
  border: none;
  color: $primary-color;
  font-size: $font-size-16;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family-jakarta;
  padding: 13px 47px;
  // margin-right: 16px;
  line-height: 22px;
}

.primary-blue-btn {
  border-radius: 10px;
  background: $primary-color;
  color: $color-white;
  font-size: $font-size-16;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family-jakarta;
  line-height: 22px;
  width: 150px;
  padding: 13px;
  border: none;
}

.discard-btn-modal {
  color: $primary-color;
  font-family: $font-family-jakarta;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  background-color: #fff;
  border: none;
  padding: 16px;
}

.primary-blue-btn-small {
  background-color: $primary-color;
  border-radius: 10px;
  padding: 16px 32px;
  font-family: $font-family-jakarta;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  border: none;
  color: #fff;
  line-height: 16px;
}
.primary-blue-btn-small-disabled {
  background-color: $neutral-30;
  color: $neutral-50;
  border-radius: 10px;
  padding: 16px 32px;
  font-family: $font-family-jakarta;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  border: none;
  line-height: 16px;
}

// -------- common-modal-style --------------

.modal-tag-and-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .modal-black-tag {
    height: 24px;
    width: 8px;
    background: $color-dark;
    border-radius: 10px;
    margin-right: 12px;
  }

  .modal-main-title {
    h1 {
      font-family: $font-family-jakarta;
      font-size: $font-size-20;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      line-height: 28px;
      text-transform: capitalize;
      color: $neutral-90;
      margin-bottom: 0px;
    }
  }
}

// ------ common-message-notification-position-css -------

.common-message-notification-position {
  position: absolute;
  top: 85px;
  right: 32px;
  width: 396px;
}

.publish-notification-popup {
  border-radius: 10px;
  padding: 28px;
  border-left: 2px solid $success-color;
  background: #fff;
  box-shadow: 0px 3px 5px 0px rgba(47, 47, 47, 0.2),
    0px 0px 1px 0px rgba(47, 47, 47, 0.2);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  animation-name: fadein;
  animation-duration: 1.5s;

  .warning-and-message-flex {
    display: flex;
    align-items: flex-start;

    .warning-icon {
      margin-right: 12px;
    }

    .notification-title-new {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-medium;
        color: $neutral-80;
        line-height: 22px;
        margin-bottom: 0px;
      }
    }

    .notification-message {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-14;
        font-style: normal;
        font-weight: $font-weight-regular;
        color: $neutral-80;
        line-height: 20px;
        letter-spacing: 0.14px;
        margin-top: 12px;
        margin-bottom: 0px;
      }
    }
  }

  .message-close-icon {
    img {
      cursor: pointer;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.unpublish-notification-popup {
  border-radius: 10px;
  padding: 28px;
  border-left: 2px solid #ffb92a;
  background: #fff;
  box-shadow: 0px 3px 5px 0px rgba(47, 47, 47, 0.2),
    0px 0px 1px 0px rgba(47, 47, 47, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation-name: fadein;
  animation-duration: 1s;

  .warning-and-message-flex {
    display: flex;
    align-items: center;

    .warning-icon {
      margin-right: 12px;
    }

    .notification-title-new {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-medium;
        color: $neutral-80;
        line-height: 22px;
        margin-bottom: 0px;
      }
    }

    .notification-message {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-14;
        font-style: normal;
        font-weight: $font-weight-regular;
        color: $neutral-80;
        line-height: 20px;
        letter-spacing: 0.14px;
        margin-top: 12px;
        margin-bottom: 0px;
      }
    }
  }

  .message-close-icon {
    img {
      cursor: pointer;
    }
  }
}

// ------ blank-page-status -------

.new-blank-page-status-sku {
  height: 40vh !important;
}

.blank-page-status {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .page-status-center {
    .no-product-found-title {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-36;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        color: #1b1b1b;
        margin-bottom: 4px;
      }

      span {
        font-family: $font-family-jakarta;
        font-size: $font-size-14;
        font-style: normal;
        font-weight: $font-weight-medium;
        color: #8d8d8d;
      }
    }

    .add-rules-button-modal {
      .common-add-rule-btn {
        display: flex;
        align-items: center;
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        color: $color-white;
        border: none;
        border-radius: 10px;
        background: $primary-color;
        justify-content: center;
        width: 100%;
        padding: 12px;
        margin-top: 24px;

        img {
          margin-right: 10px;
        }
      }
    }
  }
}

.form-filed-shopify-common {
  // width: 55%;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .form-filed-shopify-common {
    width: 80%;
  }
}

@media only screen and (max-width: 991px) {
  .main-content {
    overflow-y: unset;
    height: 100%;
    background: $neutral-20;
  }

  html,
  body {
    background: $neutral-20;
  }

  .common-message-notification-position {
    top: 85px;
    right: 0px;
    left: 0px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .publish-notification-popup {
    padding: 20px;
  }

  .blank-page-status {
    .page-status-center {
      .no-product-found-title {
        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .common-message-notification-position {
    width: 90%;
  }
}

.single-message-css-align-item {
  align-items: center !important;
}

// ---- page-note-found-404-page-css -----
.page-note-found-section {
  text-align: center;
  padding-top: 100px;

  .page-note-found-title {
    p {
      font-family: $font-family-jakarta;
      font-size: $font-size-32;
      font-style: normal;
      font-weight: $font-weight-bold;
      color: #1b1b1b;
      line-height: 48px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}


.table-header th {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.14px;
  text-transform: capitalize;
  color: #A0A8B0;
  padding: 16px 12px;
  background: #fafafa!important;
}
.table-body td {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #3B4453;
  padding: 32px 12px 20px 12px;
}