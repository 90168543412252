.common-header {
  padding: 15px 32px;
  border-left: 2px solid $neutral-20;
  margin-left: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .user-dropdown {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;

    .dropdown {
      .dropdown-toggle {
        background: transparent;
        color: $color-dark;
        font-family: $font-family-jakarta;
        font-size: $font-size-14;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 20px;
        letter-spacing: 0.14px;
        text-transform: capitalize;
        border: none;
        border-left: 2px solid $neutral-20;
        border-radius: unset;
        padding: 0px 0px 0px 20px;
        display: flex;
        align-items: center;

        &::after {
          display: inline-block;
          margin-left: 0;
          vertical-align: 0;
          content: "";
          border-top: none;
          border-right: none;
          border-bottom: 0;
          border-left: 0;
        }

        .user-name-logo {
          margin-right: 8px;
          border-radius: 50%;
          background: $user-logo-bg;
          padding: 10px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: $color-white;
            font-size: $font-size-14;
            font-weight: $font-weight-bold;
            line-height: 20px;
            text-transform: uppercase;
            margin-bottom: 0px;
          }
        }

        .dropdown-icon {
          margin-left: 10px;
          transition: all 0.3s ease-out;
        }
      }
    }

    .show {
      .dropdown-toggle {
        .dropdown-icon {
          transform: rotate(-180deg);
          transition: all 0.3s ease-out;
        }
      }

      .dropdown-menu {
        position: absolute !important;
        inset: unset !important;
        transform: unset !important;
        right: 0px !important;
        top: 58px !important;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 12px;
        border: none;
        width: 270px;

        .dropdown-item {
          padding: 12px 8px;
          font-family: $font-family-jakarta;
          font-size: $font-size-16;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 22px;
          color: $neutral-90;

          &:hover {
            border-radius: 10px;
            background: $neutral-20;
          }

          img {
            margin-right: 16px;
          }
        }

        .gap-item {
          height: 2px;
          width: 100%;
          background: $neutral-30;
          margin: 8px 0px;
        }
      }
    }
  }
}

.mobile-view-btn {
  display: none;

  button {
    background: transparent;
    border: none;

    img {
      height: 25px;
    }
  }
}
// @media only screen and (max-width: 1400px) {
//   .main-content .main-content-inner .main-content-box {
//     display: inline-block;
//   }
// }

@media only screen and (max-width: 991px) {
  .mobile-view-btn {
    display: block;
  }
  .sidebar {
    background-color: #fff;
    z-index: 999;
    box-shadow: 0px 3px 5px 0px rgba(47, 47, 47, 0.20), 0px 0px 1px 0px rgba(47, 47, 47, 0.20);
  }
    .common-header {
      margin-left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      background-color: #fff;
      z-index: 99;
      box-shadow: 0 .15rem .25rem rgba(10,12,16,.05)!important;
  }
  .main-container {
    margin-left: 0px !important;
  }
  .sidebar-none{
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .common-header {
    padding: 15px 18px;
  }
  .common-header .user-dropdown .show .dropdown-menu .dropdown-item {
    padding: 4px 4px;
  }
  .common-header .user-dropdown .show .dropdown-menu {
    width: 210px;
  }
}

