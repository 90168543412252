.login-page {
    .login-image {
        img {
            width: 100%;
            height: 100vh;
            min-height: 600px;
        }
    }

    .login-details-section {
        .login-details-section-inner {
            padding-top: 50px;
            width: 80%;
            margin: 0 auto;

            .campany-logo {
                margin-bottom: 40px;
            }

            .back-button {
                margin-bottom: 136px;

                button {
                    border: none;
                    background: transparent;
                    font-size: $font-size-18;
                    font-weight: $font-weight-semi-bold;
                    font-family: $font-family-jakarta;
                    line-height: 24px;
                    color: $color-dark;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 8px;
                    }
                }
            }

            .login-title {
                margin-bottom: 28px;

                h1 {
                    font-size: $font-size-36;
                    font-weight: $font-weight-bold;
                    font-family: $font-family-jakarta;
                    line-height: 44px;
                    margin-bottom: 12px;
                    color: $color-dark;
                }

                p {
                    font-size: $font-size-18;
                    font-weight: $font-weight-medium;
                    font-family: $font-family-jakarta;
                    line-height: 24px;
                    color: $color-dark;
                    margin-bottom: 0px;
                }
            }

            .fotgot-title {
                p {
                    font-size: $font-size-16;
                    font-weight: $font-weight-regular;
                    font-family: $font-family-jakarta;
                    line-height: 22px;
                    margin-bottom: 32px;
                    color: $neutral-50;
                }
            }

            .login-form-start {
                .form-group {
                    margin-bottom: 24px;
                    position: relative;

                    .common-form-lable {
                        font-size: $font-size-14;
                        margin-bottom: 6px;
                        font-weight: $font-weight-semi-bold;
                        line-height: 20px;
                    }

                    .form-control {
                        background: $neutral-20;
                        font-family: $font-family-jakarta;
                        font-weight: $font-weight-regular;
                        font-size: $font-size-16;
                        color: $neutral-60;
                        height: 48px;
                        padding: 14px 13px;
                        border-radius: 8px;
                        outline: none;
                        border: none;
                        width: 100%;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }

                        &::placeholder {
                            font-family: $font-family-jakarta;
                            font-weight: $font-weight-regular;
                            font-size: $font-size-16;
                            color: $neutral-60;
                            line-height: 22px;
                        }

                    }

                    .psw-hide-show {
                        background-color: transparent;
                        border: none;
                        position: absolute;
                        right: 15px;
                        top: 39px;
                    }
                }

                .check-box-and-forgot-psw {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 16px;

                    label {
                        font-family: $font-family-jakarta;
                        font-weight: $font-weight-regular;
                        font-size: $font-size-16;
                        line-height: 27px;
                        color: $neutral-80;
                    }

                    button {
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        font-family: $font-family-jakarta;
                        font-weight: $font-weight-semi-bold;
                        font-size: $font-size-16;
                        line-height: 22px;
                        color: $color-dark;
                        text-decoration: underline;
                    }

                    .form-check-input:checked {
                        background-color: $primary-color;
                        border-color: $primary-border-color;
                    }

                    .form-check-input:focus {
                        box-shadow: none;
                    }

                    .form-check-input:checked[type="checkbox"] {
                        background-image: url("../../../public/images/check.svg");
                        background-size: auto;
                    }

                    .form-check-input {
                        width: 20px;
                        height: 20px;
                        border: 1px solid $neutral-60;
                        border-radius: 6px;
                        margin-right: 6px;
                    }
                }

                .create-account {
                    margin-top: 32px;
                    display: flex;

                    p {
                        font-family: $font-family-jakarta;
                        font-weight: $font-weight-medium;
                        font-size: $font-size-18;
                        line-height: 24px;
                        color: $color-dark;
                        margin: 0;
                    }

                    button {
                        font-family: $font-family-jakarta;
                        font-weight: $font-weight-medium;
                        font-size: $font-size-18;
                        line-height: 24px;
                        color: $primary-color;
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        margin-left: 5px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.phone-input {
    .form-control {
        padding-left: 50px !important;
    }
}

.reset-psw {
    margin-bottom: 150px !important;
}

.reset-link-title p {
    font-family: $font-family-jakarta;
    font-weight: $font-weight-medium;
    font-size: $font-size-22;
    line-height: 22px;
    color: $neutral-80;
}

.click-to-resend-title {
    margin-top: 40px;

    p {
        font-family: $font-family-jakarta;
        font-weight: $font-weight-regular;
        font-size: $font-size-16;
        line-height: 22px;
        color: $neutral-80;

        a {
            font-weight: $font-weight-semi-bold;
            color: $color-dark;
            cursor: pointer;
            text-decoration: underline !important;
            color: $primary-color;
        }
    }
}


// ----- common-log-in-btn -------

.login-button {
    button {
        background: $primary-color;
        font-family: $font-family-jakarta;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-16;
        color: #ffffff;
        line-height: 16px;
        padding: 16px;
        text-align: center;
        width: 100%;
        border: none;
        border-radius: 10px;
        margin-top: 32px;
    }
}


// ---- psw-successful-css ----

.psw-successful {
    text-align: center;

    .right-icon {
        margin-bottom: 24px;
    }
}

@media only screen and (max-width: 1600px) {
    .register-details-page {
        max-height: 100vh;
        overflow-y: auto;

        .register-details-section-inner {
            padding-top: 20px !important;

            .campany-logo {
                margin-bottom: 15px !important;
            }

            .login-title {
                margin-bottom: 15px !important;
            }

            .form-group {
                margin-bottom: 15px !important;
            }

            .login-button {
                button {
                    margin-top: 15px !important;
                }
            }

            .create-account {
                margin-top: 15px !important;
            }

            .common-error-message {
                margin-bottom: 5px !important;
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .login-page .login-details-section .login-details-section-inner .login-form-start .check-box-and-forgot-psw label {
        font-size: 14px;
    }

    .login-page .login-details-section .login-details-section-inner .login-form-start .check-box-and-forgot-psw button {
        font-size: 14px;
    }

    .login-button button {
        margin-top: 24px;
    }

    .login-page .login-details-section .login-details-section-inner .login-form-start .form-group {
        margin-bottom: 18px;
    }

    .login-page .login-details-section .login-details-section-inner .campany-logo {
        margin-bottom: 28px;
    }

    .login-page .login-details-section .login-details-section-inner .login-title {
        margin-bottom: 22px;
    }

    .login-page .login-details-section .login-details-section-inner {
        padding-top: 20px;
    }
}


.react-tel-input .country-list {
    max-height: 195px !important;
}

@media only screen and (max-width: 991px) {
    .login-page .login-details-section .login-details-section-inner {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 90%;
    }

    .login-page .login-details-section .login-details-section-inner .campany-logo {
        margin-bottom: 15px;
    }

    .login-page .login-details-section .login-details-section-inner .login-form-start .create-account {
        margin-top: 20px;
    }

    .login-page .login-image img {
        object-fit: cover;
    }
}

@media only screen and (max-width: 767px) {
    .login-image {
        display: none !important;
    }

    .login-page .login-details-section .login-details-section-inner .login-title h1 {
        font-size: 25px;
    }

    .reset-psw {
        margin-bottom: 16px !important;
    }

    .click-to-resend-title {
        margin-top: 30px;
    }
}

.login-image {
    display: block;
}


.reset-btn-new {
    background: transparent;
    border: none;
    text-decoration: underline;
    font-family: $font-family-jakarta;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-16;
    line-height: 22px;
    color: $color-dark;

    &:disabled {
        color: $neutral-50;
    }
}