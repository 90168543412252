.main-content {
  .main-content-inner {
    padding: 30px 32px;

    .main-title {
      h1 {
        font-family: $font-family-jakarta;
        font-size: $font-size-24;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 30px;
        margin-bottom: 24px;
        color: $color-dark;
      }
    }

    .main-content-box {
      padding: 24px;
      background: #fff;
      border-radius: 10px;

      .main-content-sub-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;

        .tag-and-sub-title {
          display: flex;
          align-items: center;

          .black-tag {
            height: 24px;
            width: 8px;
            background: $color-dark;
            border-radius: 10px;
            margin-right: 12px;
          }

          .main-cotent-sub-title {
            h1 {
              font-family: $font-family-jakarta;
              font-size: $font-size-20;
              font-style: normal;
              font-weight: $font-weight-semi-bold;
              line-height: 28px;
              text-transform: capitalize;
              color: $color-dark;
              margin-bottom: 0px;
            }
          }
        }

        .search-and-filter-btn {
          display: flex;
          align-items: center;

          .common-search {
            position: relative;

            .form-control {
              border-radius: 8px;
              background: $neutral-20;
              padding: 9px 14px 9px 42px;
              height: 40px;
              width: 346px;
              border: none;
              outline: none;

              &:focus {
                outline: none;
                box-shadow: none;
                border: none;
              }

              &::placeholder {
                font-family: $font-family-jakarta;
                font-weight: $font-weight-regular;
                font-size: $font-size-16;
                color: $neutral-50;
                line-height: 22px;
              }
            }

            .search-iocn {
              position: absolute;
              top: 6px;
              left: 14px;
            }
          }

          .filter-btn {
            button {
              border: none;
              border-radius: 8px;
              padding: 12px;
              background: $neutral-20;
              font-family: $font-family-jakarta;
              font-weight: $font-weight-semi-bold;
              font-size: $font-size-16;
              color: $neutral-80;
              line-height: 16px;
              margin-left: 12px;
              display: flex;
              align-items: center;

              img {
                margin-left: 10px;
              }
            }
          }

          .setting-btn {
            button {
              border: none;
              border-radius: 8px;
              padding: 12px;
              background: $neutral-20;
              font-family: $font-family-jakarta;
              font-weight: $font-weight-semi-bold;
              font-size: $font-size-16;
              color: $neutral-80;
              line-height: 16px;
              margin-left: 12px;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .main-product-table {
        overflow-x: scroll !important;
        width: 100%;

        table {
          width: 100%;

          .table-header {
            th {
              font-family: $font-family-jakarta;
              font-size: $font-size-14;
              font-style: normal;
              font-weight: $font-weight-medium;
              line-height: 20px;
              letter-spacing: 0.14px;
              text-transform: capitalize;
              color: $neutral-50;
              padding: 16px 12px;
              background: #fafafa;

              &:first-child {
                border-radius: 8px 0px 0px 8px;
              }

              &:last-child {
                border-radius: 0px 8px 8px 0px;
              }
            }
          }

          .table-body {
            vertical-align: top !important;
            border-bottom: 1px solid $neutral-30;

            td {
              font-family: $font-family-jakarta;
              font-size: $font-size-16;
              font-style: normal;
              font-weight: $font-weight-semi-bold;
              line-height: 22px;
              color: $neutral-80;
              padding: 32px 12px 20px 12px;

              a {
                text-decoration: none;
                color: $neutral-80;
              }
            }

            .publish-status {
              text-transform: capitalize;
              border-radius: 20px;
              background: rgba(204, 224, 255, 0.5);
              padding: 4px 16px;
              width: auto;
              display: inline-block;

              p {
                font-family: $font-family-jakarta;
                font-size: $font-size-14;
                font-style: normal;
                font-weight: $font-weight-medium;
                color: $info;
                margin-bottom: 0px;
                letter-spacing: 0.14px;
              }
            }

            .unpublish-status {
              text-transform: capitalize;
              border-radius: 20px;
              background: $neutral-30;
              padding: 4px 16px;
              width: auto;
              display: inline-block;

              p {
                font-family: $font-family-jakarta;
                font-size: $font-size-14;
                font-style: normal;
                font-weight: $font-weight-medium;
                color: $neutral-50;
                margin-bottom: 0px;
                letter-spacing: 0.14px;
              }
            }
          }
        }
      }
    }

    .selected-product-status-section {
      padding: 11px 24px;
      border-radius: 10px;
      margin-top: 24px;
      background: $color-white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      bottom: 0px;
      z-index: 99;

      .product-status {
        display: flex;
        align-items: center;

        .selected-product-icon {
          margin-right: 8px;
        }

        .product-selected-title {
          p {
            font-family: $font-family-jakarta;
            font-size: $font-size-14;
            font-style: normal;
            font-weight: $font-weight-semi-bold;
            line-height: 20px;
            letter-spacing: 0.14px;
            color: $neutral-50;
            text-transform: capitalize;
            margin-bottom: 0px;
          }
        }
      }

      .unpublish-and-publish-btn {
        display: flex;
        align-items: center;
      }
    }
  }
}

// ------- filter-modal-css ---------
.filter-modal-style {
  padding: 24px;

  .modal-header {
    padding: 0px !important;
  }

  .modal-body {
    padding: 0px !important;

    .modal-main-content {
      padding: 24px 0px;

      .main-content-title {
        p {
          font-family: $font-family-jakarta;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          line-height: 20px;
          color: $neutral-90;
          margin-bottom: 0px;
          margin-bottom: 18px;
        }
      }

      .categories-wise-checkbox {
        margin-bottom: 26px;

        .form-check {
          display: -webkit-inline-box;
        }

        label {
          font-family: $font-family-jakarta;
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-16;
          line-height: 22px;
          color: $neutral-80;
        }

        .form-check-input:checked {
          background-color: $primary-color;
          border-color: $primary-border-color;
        }

        .form-check-input:focus {
          box-shadow: none;
        }

        .form-check-input:checked[type="checkbox"] {
          background-image: url("../../../public/images/check.svg");
          background-size: auto;
        }

        .form-check-input {
          width: 20px;
          height: 20px;
          border: 1px solid $neutral-60;
          border-radius: 6px;
          margin-right: 6px;
        }
      }
    }
  }

  .modal-footer {
    padding: 0px !important;

    .cancel-and-apply-btn {
      margin-top: 8px;
    }
  }
}

//   ------ unpulish-modal-style ------

.unpublish-modal-style {
  padding: 24px;

  .modal-header {
    padding: 0px !important;
    border: none !important;

    .modal-black-tag {
      background: $color-yellow;
    }
  }

  .modal-body {
    padding: 0px !important;

    .publish-modal-content {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-regular;
        color: $neutral-80;
        margin-bottom: 0px;
        margin-bottom: 30px;
      }
    }
  }

  .modal-footer {
    padding: 0px !important;
    border: none !important;

    .cancel-and-apply-btn {
      margin-top: 8px;

      .publish-bg-green {
        background: $color-yellow;
      }

      .publish-new-color {
        color: $neutral-50;
      }
    }
  }
}

// --------- publish-modal-style ---------

.publish-modal-style {
  padding: 24px;

  .modal-header {
    padding: 0px !important;
    border: none !important;

    .modal-black-tag {
      background: $success-color;
    }
  }

  .modal-body {
    padding: 0px !important;

    .publish-modal-content {
      p {
        font-family: $font-family-jakarta;
        font-size: $font-size-16;
        font-style: normal;
        font-weight: $font-weight-regular;
        color: $neutral-80;
        margin-bottom: 0px;
        margin-bottom: 30px;
      }
    }
  }

  .modal-footer {
    padding: 0px !important;
    border: none !important;

    .cancel-and-apply-btn {
      margin-top: 8px;

      .publish-bg-green {
        background: $success-color;
      }

      .publish-new-color {
        color: $neutral-50;
      }
    }
  }
}

// ----- woocommerce-and-shopify-page-css -----
.woocommerce-and-shopify-section {
  .main-content-inner {
    .main-content-box {
      width: 55%;
    }
  }
}

// Setting csss----------

.setting-form {
  .custom-field {
    .setting-lable {
      color: $color-dark;
      font-family: $font-family-jakarta;
      font-size: $font-size-16;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      line-height: 22px;
    }

    .form-control {
      color: $color-dark;
      font-family: $font-family-jakarta;
      font-size: $font-size-16;
      font-style: normal;
      font-weight: $font-weight-regular;
      line-height: 22px;
      padding: 13px 14px;
      border-radius: 8px;
      border: 1px solid $neutral-30;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);

      &::placeholder {
        color: $neutral-80;
      }
    }
  }
}

.setting-form {
  .disabled-field {
    margin-top: 32px;

    .setting-lable {
      color: $neutral-50;
      font-family: $font-family-jakarta;
      font-size: $font-size-14;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      line-height: 20px;
      letter-spacing: 0.14px;
      text-transform: capitalize;
    }

    .setting-name {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: $neutral-80;
        font-family: $font-family-jakarta;
        font-size: $font-size-14;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 22px;
      }

      .change-pass-btn {
        button {
          padding: 12px;
          border-radius: 10px;
          border: 1px solid $primary-color;
          background-color: #fff;
          color: $primary-color;
          font-size: $font-size-16;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          line-height: 16px;
          font-family: $font-family-jakarta;
        }
      }
    }
  }
}

.logout-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  button {
    padding: 12px 32px;
    border-radius: 10px;
    background: $color-red;
    color: #fff;
    font-family: $font-family-jakarta;
    font-size: $font-size-16;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    line-height: 16px;
    border: none;
  }
}

// ------ edit-modal-style ------

.edit-password-modal {
  padding: 24px;

  .modal-header {
    border: none;
    padding: 0px;
  }

  .modal-body {
    padding: 24px 0px;

    .model-form {
      .model-field {
        margin-bottom: 40px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        .form-label {
          color: $neutral-80;
          font-family: $font-family-jakarta;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          line-height: 20px;
          text-transform: capitalize;
          margin-bottom: 6px;
        }

        .form-control {
          color: $neutral-80;
          font-family: $font-family-jakarta;
          font-size: $font-size-16;
          font-style: normal;
          font-weight: $font-weight-regular;
          line-height: 22px;
          padding: 13px 14px;
          border-radius: 8px;
          border: 1px solid $neutral-30;
          box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
          background: $color-white;

          &::placeholder {
            color: $neutral-40;
          }

          // &:hover{
          //   border-color: $primary-color;
          // }
          // &:active{
          //   border-color: #ff7259;
          // }
          // &:focus{
          //   border-color: #59ff64;
          // }
        }

        .psw-hide-show {
          background-color: transparent;
          border: none;
          position: absolute;
          right: 15px;
          top: 40px;
        }
      }
    }
  }

  .modal-footer {
    border: none;
    padding: 16px 0px 0px 0px;
  }
}

.product-image {
  mix-blend-mode: multiply;
  filter: contrast(1);
  height: 100%;
  width: 100%;
}

.common-three-button {
  .ctm-disabled {
    background-color: $neutral-30;
    color: $neutral-50;
    padding: 16px;
  }

  .ctm-button {
    background-color: #dfdeff;
    color: $primary-color;
    padding: 16px;
  }

  button {
    margin-left: 12px;
  }

  .ctm-connectivity {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: #fff;
    padding: 16px;
  }
}

.modal-header .btn-close {
  position: relative;
  top: -8px;
}

.image-bg-fixed {
  height: 86px;
  width: 86px;
  background: $image-bg-color;
  padding: 10px;
  border-radius: 10px;
}

.new-detele-btn {
  button {
    background: $color-red !important;
    color: $color-white !important;
  }
}

@media only screen and (max-width: 1400px) {
  .main-content .main-content-inner .main-content-box .main-product-table table .table-header th {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 991px) {
  .woocommerce-and-shopify-section .main-content-inner .main-content-box {
    width: 100%;
  }

  body .form-filed-shopify-common {
    width: 100%;
  }

  .main-content .main-content-inner {
    padding: 95px 32px 24px;
  }

  body .rules-box {
    padding-bottom: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .main-content .main-content-inner .main-content-box .main-content-sub-header {
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 32px;
    flex-direction: column;
  }

  .main-content .main-content-inner .selected-product-status-section {
    flex-direction: column;
  }

  .main-content .main-content-inner .selected-product-status-section .product-status {
    margin-bottom: 20px;
  }

  .main-content .main-content-inner .main-content-box .main-content-sub-header .search-and-filter-btn .common-search .form-control {
    width: 100%;
  }

  .main-content .main-content-inner .main-content-box .main-content-sub-header .search-and-filter-btn {
    margin-top: 15px;
  }

  .product-pagination {
    align-items: flex-start !important;
    flex-direction: column;
  }

  .product-num-page {
    margin-bottom: 20px;
  }

  .main-content .main-content-inner .main-content-box {
    padding: 15px;
  }

  .main-content .main-content-inner {
    padding: 95px 18px 24px;
  }

  .main-content .main-content-inner .main-content-box .main-product-table table .table-body td {
    font-size: 14px;
    padding: 20px 12px 15px 12px;
  }

  .product-image {
    // height: 70px;
    // width: 70px;
    object-fit: fill;
  }

  .image-bg-fixed {
    height: 70px;
    width: 70px;
  }

  .main-content .main-content-inner .main-title h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .main-content .main-content-inner .main-content-box .main-content-sub-header .tag-and-sub-title .main-cotent-sub-title h1 {
    font-size: 18px;
  }

  .setting-form .custom-field .setting-lable {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 440px) {
  .listing-pagination .page-link {
    padding: 8px;
  }

  .product-pagination .listing-pagination .dropdown button {
    padding: 6px 8px 6px 8px !important;
  }
}


.check-box-grid {
  padding: 0px 12px;
}

.setting-check-box-scroll {
  height: calc(100vh - 300px);
  overflow-y: auto;
}



// ----- edit-psw-modal-custom-width -----
.setting-model {
  .modal-dialog {
    max-width: 648px !important;
  }
}


// ------- publish-modal-custom-width -------
.publish-and-unpublish-modal-custom-width {
  .modal-dialog {
    max-width: 597px !important;
  }
}


// ------- filter-modal-custom-width -------
.filter-modal {
  .modal-dialog {
    max-width: 504px !important;
  }
}


.product-catefory-flex {
  display: flex !important;
  flex-direction: column !important;

  span {
    width: 178px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}